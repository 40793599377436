import { FC } from 'react';
// Interfaces
import { IconSvgProps } from '@utmb/types/icons';

const ChevronRight: FC<IconSvgProps> = ({ color, clss }) => {
    const styles = {
        svg: {
            verticalAlign: 'middle',
            overflow: 'hidden',
        },
    };

    return (
        <svg width="7" height="11" viewBox="0 0 7 11" className={clss} style={{ ...styles.svg }}>
            <path fill={color} d="m.986 1.286 3.458 3.729L.986 8.744l1.198 1.286L6.24 5.658l.596-.643-.596-.643L2.185 0z" />
        </svg>
    );
};

export default ChevronRight;
